$break-large: 1170px;
$break-tablet: 768px;
$break-mobile: 480px;

// FIXME This is defined globally now - remove this file
.article-card-item {
  position: relative;
  margin: 16px auto 4px;
  width: 100%;
  max-width: 580px;
  box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);

  .image {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    border-radius: 3px;
    box-shadow: none;
    bottom: auto;
    position: relative;

    .video {
      width: 100%;
    }

    .img {
      width: 100%;
      height: 233px;
      background-size: cover;
      background-position: center center;
      box-shadow: none;
    }
  }

  .content {
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: none;
    float: none;
    position: relative;
    min-height: auto;
    margin-top: 0;
    margin-left: 0;
    width: 100%;

    &.margin {
      margin-top: 3%;
    }

    .margin-left {
      margin-left: 300px;
    }

    .icon {
      float: right;
      margin-right: 0;

      .bookmark, .heart, .comment {
        float: right;
        font-size: 12px;
        color: #bbb;
      }

      .bookmark, .comment {
        margin-left: 10px;
      }
    }

    .author {
      bottom: 10px;
      font-size: 1em;
      clear: both;
      float: left;
      left: 0px;
      position: relative;
      padding: 20px 20px 0px;
      width: 100%;

      a {
        opacity: 0.7;
      }
    }
  }

  .content-right {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-height: auto !important;
  }

  h3 {
    font-size: 1.3em;
    margin: 16px 0 10px;
    @media screen and (max-width: $break-mobile) {
      //padding: 0 24px;
    }
  }

  .intro {
    font-size: 1em;
    margin: 20px 0;
    text-align: justify;
    //padding-right: 26px;
    min-height: 116px;
    float: left;
    width: 100%;

    h3 {
      font-size: 1em;
    }
  }
}

.content-bottom {
  position: relative;
  left: 20px;
  width: calc(100% - 20px);

  .shop-logo {
    margin-right: 20px;

    width: auto;
    max-height: 50px;
    display: block;
  }
}
